/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .sorter {
  left: -230px;
}
.altai-theme-content #bodyView .no-results {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 30px;
  font-weight: 500;
}
.altai-theme-content #bodyView .comeos-search-bar {
  position: relative;
}
.altai-theme-content #bodyView .comeos-search-bar input[type='text'] {
  background-color: white;
  width: 100%;
  border: 1px solid #EDEDED;
  border-radius: 5px;
}
.altai-theme-content #bodyView .comeos-search-bar input[type='text']::-webkit-input-placeholder {
  color: black !important;
  font-style: normal;
}
.altai-theme-content #bodyView .comeos-search-bar .chevron {
  position: absolute;
  right: 40px;
  top: 9px;
  font-size: 35px;
  margin: auto;
}
.altai-theme-content #bodyView .container-content ul {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
}
.altai-theme-admin #bodyView .sortheader {
  cursor: pointer;
}
.exposed-collection-aggregations {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .altai-theme-content #bodyView .sorter {
    left: inherit;
  }
}
